<template>
  <div class="login">
    <div class="content">
      <div class="form">
        <div class="nav">
          <span :class="{ act: act == 1 }" @click="act = 1">会员卡激活</span>
          <!-- <span :class="{ act: act == 2 }" @click="act = 2">普通用户注册</span> -->
        </div>
        <div v-if="act == 1">
          <p>
            <el-input v-model="card" class="login-input" placeholder="卡号">
            </el-input>
          </p>
          <p>
            <el-input v-model="password" class="login-input" placeholder="密码" show-password>
            </el-input>
          </p>
          <p>
            <el-input v-model="mobile" class="login-input" placeholder="手机号">
              <template slot="prepend">+86</template>
            </el-input>
          </p>
          <p style="display: flex; justify-content: space-between">
            <el-input v-model="code" style="margin-right: 10px" class="login-input code" placeholder="请输入验证码" />
            <el-button class="getCode" type="success" @click="getCode">{{
              btn
            }}</el-button>
          </p>
          <div v-if="register_btn" @click="register" class="login_btn_ture">
            激活
          </div>
          <div v-else class="login_btn">激活</div>
        </div>
      </div>
    </div>

    <el-dialog modal="true" :show-close="false" :visible.sync="dialogVisible" :close-on-click-modal="false" class="dia_msg_2"
      style="top: 144px" width="520px">
      <div class="title">
        <img src="@/assets/img/login/icon_2.png" alt="" />
        <div>恭喜你，注册成功！</div>
      </div>
      <ul class="dialog_form">
        <li>
          <div>您注册的会员卡号为{{ form.account }} 密码{{ password }}</div>
        </li>
        <li>
          <div>您可以通过该手机号+密码形式登录</div>
        </li>
        <li>
          <div>
            是否修改密码 (如果忘记密码可以通过绑定的手机号密码进行密码找回)
          </div>
        </li>
        <li>
          <div>该卡号激活的手机号：{{ form.mobile }}</div>
        </li>
      </ul>
      <div class="c_input">
        <el-input v-model="new_password" class="login-input" placeholder="请输入新密码" show-password>
        </el-input>
      </div>
      <span class="c_btn">
        <el-button @click="next()" round style="margin-right: 68px" size="mini">跳过</el-button>
        <el-button type="primary" round size="mini" @click="changePwd">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog :show-close="true" modal="true" :visible.sync="dialogVisibles" :close-on-click-modal="false"
      class="dia_msg" style="top: 104px; right: 18px" width="380px">
      <div class="title">
        <img src="@/assets/img/login/icon_1.png" alt="" />
        <span>提醒</span>
      </div>
      <div class="msg">{{ msg }}</div>
      <div class="btn" v-if="state">
        <router-link to="/login">
          <el-button size="mini" round>马上登录</el-button>
        </router-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Register",
  computed: {
    changeData() {
      const { mobile, password, card, code } = this;
      return {
        card,
        code,
        mobile,
        password,
      };
    },
  },
  watch: {
    changeData() {
      if (this.mobile && this.password && this.card && this.code) {
        this.register_btn = true;
      } else {
        this.register_btn = false;
      }
    },
  },
  data() {
    return {
      btn: "获取验证码",
      time: 60,
      act: 1,
      register_btn: false,
      timer: null,
      mobile: "",
      code: "",
      card: "",
      password: "",
      dialogVisible: false,
      dialogVisibles: false,
      new_password: "",
      form: {
        card: "",
        password: "",
        mobile: "",
      },
      msg: "",
      state: 1,
    };
  },
  methods: {
    ...mapMutations(["setInfo"]),
    // 获取验证码
    getCode() {
      if (!this.VerifyPhone()) {
        return;
      }
      if (this.time === 60) {
        this.$fecth
          .post("user/sendPhoneCode", {
            mobile: this.mobile,
          })
          .then(() => {
            this.$message({
              showClose: true,
              message: "发送成功！",
              type: "success",
            });
            this.timer = setInterval(() => {
              this.time--;
              this.btn = this.time + "s后重新发送";
              if (this.time < 1) {
                clearInterval(this.timer);
                this.timer = null;
                this.time = 60;
                this.btn = "获取验证码";
              }
            }, 1000);
          });
      }
    },
    // 会员注册
    register() {
      if (!this.VerifyPhone()) {
        return;
      }

      if (!this.code) {
        this.$message("请输入验证码");
        return;
      }
      if (!this.card) {
        this.$message("请输入卡号");
        return;
      }
      if (!this.password) {
        this.$message("请输入密码");
        return;
      }
      this.$fecth
        .post("user/CardRegister", {
          card: this.card,
          mobile: this.mobile,
          password: this.password,
          code: this.code,
        })
        .then((res) => {
          if (res) {
            if (res.code == 301) {
              this.dialogVisibles = true;
              this.msg = res.msg;
              this.state = 0;
              return false;
            }
            this.state = 1
            this.$notify({
              showClose: true,
              message: "恭喜你，注册成功！",
              type: "success",
            });
            this.setInfo(res);
            localStorage.setItem("UserInfo", JSON.stringify(res));
            localStorage.setItem("TOKEN", res.token);
            this.form = res;
            this.dialogVisible = true;
          }
        });
    },

    VerifyPhone() {
      let reg = /^1[0-9]{10}$/;
      if (
        this.mobile == "" ||
        this.mobile.length <= 10 ||
        !reg.test(this.mobile)
      ) {
        this.$message("手机号格式错误");
        return;
      } else {
        return true;
      }
    },
    changePwd() {
      if (!this.new_password) {
        this.$message("请输入新密码");
        return;
      }
      this.$fecth
        .post("user/changePwd", {
          mobile: this.mobile,
          password: this.new_password,
        })
        .then((res) => {
          if (res) {
            this.$router.push("/information");
          }
        });
    },
    next() {
      this.$router.push("/information");
    },
  },
};
</script>
<style lang="less">
.dia_msg {
  .title {
    text-align: center;
    color: #2771fa;
    font-size: 16px;
    vertical-align: middle;

    img {
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      margin-left: 10px;
      letter-spacing: 3px;
    }

    margin-bottom: 30px;
  }

  .msg {
    text-align: center;
  }

  .btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;

    .el-button {
      border: 1px solid #2771fa;
      color: #2771fa;
      width: 150px;
    }
  }

  .el-dialog__body {
    padding: 20px 40px;
  }

  .el-dialog__header {
    padding: 0;
  }
}

.login-input {

  // width: 285px;
  .el-input__inner {
    background: #f4f4f4;
    font-size: 16px;
    border: solid 1px #dddddd;
  }

  .el-input-group__prepend {
    background: #f4f4f4;
    font-size: 16px;
    border: solid 1px #dddddd;
  }
}

.code {
  .el-input__inner {
    background: white;
    font-size: 16px;
    border: solid 1px #dddddd;
  }
}

.dia_msg_2 {
  .title {
    text-align: center;
    font-size: 18px;
    color: #2771fa;
    padding: 35px 40px 20px;
    border-bottom: 2px solid #b3b3b3;
  }

  .el-dialog__body {
    height: 400px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="less" scoped>
.getCode {
  background: #2771fa;
  border: 0;
}

.login {
  background: url("../assets/img/login/login_bg.jpg");
  min-height: 770px;
  overflow: hidden;

  .content {
    width: 1200px;
    margin: 0 auto;
  }

  .form {
    position: relative;
    width: 315px;
    margin: 115px auto 0;
    min-height: 320px;
    height: 373px;
    background: white;
    padding: 42px 33px 36px;
    border-radius: 7px;
    padding-bottom: 60px;

    .nav {
      border-bottom: 1px solid #b3b3b3;
      margin-bottom: 30px;
      font-size: 18px;
      padding-bottom: 5px;
      color: #b3b3b3;

      span {
        padding-bottom: 5px;
        margin-right: 14px;
      }
    }

    .act {
      color: black;
      position: relative;
      // top: 1px;
      padding-bottom: 1px;
      border-bottom: 2px solid #2771fa;
    }

    p {
      margin: 0 auto 25px;
    }

    .login_btn {
      font-size: 16px;
      color: #666666;
      background: #dddddd;
      border: 1px solid #f4f4f4;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
    }

    .login_btn_ture {
      background: #2771fa;
      line-height: 40px;
      color: white;
      text-align: center;
      letter-spacing: 3px;
      font-size: 16px;
      border-radius: 4px;
    }
  }

  .dialog_form {
    padding: 10px 80px 0;

    li {
      line-height: 25px;
    }
  }

  .c_input {
    text-align: center;
    padding: 10px 80px 30px;
  }

  .c_btn {
    text-align: center;
    padding: 10px 80px;

    .el-button {
      width: 140px;
      height: 28px;
    }

    margin-bottom: 40px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  font-size: 16px;
  color: #b3b3b3;

  a {
    color: #b3b3b3;
  }
}
</style>